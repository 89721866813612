import React from "react";
import { Navigate } from "react-router-dom";

import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";


import Register2 from "../pages/AuthenticationInner/Register2";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2";
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail";
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2";
import EmailVerification from "../pages/Authentication/EmailVerification";
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2";
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification";
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2";

// Dashboard
import Dashboard from "../pages/Dashboard/index";


//Profile pages
import Profile from "../pages/smarteeva-pages/profile/profile";
import MyData from "../pages/smarteeva-pages/profile/My submissions";



// //Pages
// import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
// import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesFaqs from "../pages/Utility/pages-faqs";
// import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import ResetPassword from "../pages/Authentication/ResetPassword";

import ChangePassword from "pages/smarteeva-pages/ChangePassword";

import DecisionTreeList from "pages/smarteeva-pages/Decision-Tree/DecisionTreeList";
import DecisionTreeMain from "pages/smarteeva-pages/Decision-Tree";
import EventsList from "pages/smarteeva-pages/Events/EventsList";
import AlterEvent from "pages/smarteeva-pages/Events/AlterEvent";
import ComplaintList from "pages/smarteeva-pages/Complaints/ComplaintList";
import AddComplaint from "pages/smarteeva-pages/Complaints/AddComplaint";

import RecallDashboard from "pages/Dashboard/RecallDashboard";
import WarningLetterDashboard from "pages/Dashboard/WarningLetterDashboard";
import AlterComplaint from "pages/smarteeva-pages/Complaints/AlterComplaint";
import LandingPage from "pages/Dashboard/LandingPage";
import LoginLanding from "pages/Authentication/LoginLanding";


const authProtectedRoutes = [
  // { path: "/dashboard", component: <Dashboard /> },

  { path: "/recall-dashboard", component: <RecallDashboard /> },
  { path: "/warning-letters", component: <WarningLetterDashboard /> },


  { path: "/profile", component: <Profile />},
  { path: "/my-data", component: <MyData />},



  { path: "/change-password", component: <ChangePassword />},


  { path: "/decision-tree", component: <DecisionTreeList />},


  { path: "/decision-tree-config", component: <DecisionTreeMain />},
  { path: "/events", component: <EventsList />},
 

  { path: "/complaints", component: <ComplaintList />},
  { path: "/create-complaint", component: <AddComplaint />},
  { path: "/alter-complaint", component: <AlterComplaint />},

  { path: "/landing-page", component: <LandingPage />},



     { path: "/support", component: <PagesFaqs /> },
  
  {
    path: "/",
    exact: true,
    component: <Navigate to="/complaints" />,
  },
  
  
];

const publicRoutes = [
  { path: "/login", component: <LoginLanding /> },
  { path: "/", component: <LoginLanding /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },


  // { path: "/forgot-password", component: <ForgetPwd2 /> },
  { path: "/reset-password", component: <ResetPassword /> },

  { path: "/maintenance", component: <PagesMaintenance /> },
  { path: "/comingsoon", component: <PagesComingsoon /> },
  { path: "/404", component: <Pages404 /> },
  { path: "/500", component: <Pages500 /> },
  { path: "/confirm-mail", component: <ConfirmMail /> },
  // { path: "/crypto-ico-landing", component: <CryptoIcoLanding /> },

  { path: "/email-verification", component: <EmailVerification /> },

  // Authentication Inner
  // { path: "/pages-login", component: <Login1 /> },
  // { path: "/pages-login-2", component: <Login2 /> },
  // { path: "/pages-register", component: <Register1 /> },
  { path: "/pages-register-2", component: <Register2 /> },
  // { path: "/page-recoverpw", component: <Recoverpw /> },
  //  { path: "/auth-recoverpw-2", component: <ForgetPwd /> },
 
  // { path: "/pages-forgot-pwd", component: <ForgetPwd1 /> },
  // { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/auth-lock-screen-2", component: <LockScreen2 /> },
  
  // { path: "/page-confirm-mail-2", component: <ConfirmMail2 /> },
  // { path: "/auth-email-verification", component: <EmailVerification /> },
  { path: "/auth-email-verification-2", component: <EmailVerification2 /> },
  { path: "/auth-two-step-verification", component: <TwostepVerification /> },
  { path: "/auth-two-step-verification-2", component: <TwostepVerification2 /> },
];

export { authProtectedRoutes, publicRoutes };
