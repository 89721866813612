import { call, put, takeEvery, takeLatest, fork } from "redux-saga/effects";

// Login Redux States
import { CHECK_SOCIAL_LOGIN, GET_USER_PERSONAL_DETAILS, GOOGLE_SOCIAL_LOGIN, LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN, VERIFY_USER_EMAIL_DETAILS } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess, checkSocialLogin, setLoadingIndicator, getUserPersonalDetails, loginPersonalUserData } from "./actions";

import { Auth } from 'aws-amplify';

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import moment from 'moment'
import { authUser, sessionStorage ,sessionExpiration, tspFullAccessToken } from "../../../helpers/dataConfig";
import config from '../../../aws-config'
import { updateUserSignupStatus, getUserDetails, validateLoginEmail, getGroupPermissionList,addUserSessionData } from "helpers/backend_helper";
import axios from "axios";
import { getTspToken } from "helpers/getTspToken";
import { setLoadingIndicatorDashboard } from "store/actions";
import { useDispatch } from "react-redux";

const fireBaseBackend = getFirebaseBackend();

function convertKeysToLowerCase(obj) {
  const newObj = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key.toLowerCase()] = obj[key];
    }
  }
  return newObj;
}

 function* loginUser( { payload: { user, history, rememberText } }) {
  yield put(loginPersonalUserData({}));

  // history('/dashboard');
  // try {
  //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //     const response = yield call(
  //       fireBaseBackend.loginUser,
  //       user.email,
  //       user.password
  //     );
  //     yield put(loginSuccess(response));
  //   } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
  //     const response = yield call(postJwtLogin, {
  //       email: user.email,
  //       password: user.password,
  //     });
  //     localStorage.setItem("authUser", JSON.stringify(response));
  //     yield put(loginSuccess(response));
  //   } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
  //     const response = yield call(postFakeLogin, {
  //       email: user.email,
  //       password: user.password,
  //     });
  //     localStorage.setItem("authUser", JSON.stringify(response));
  //     yield put(loginSuccess(response));
  //   }
  //   history('/dashboard');
  // } catch (error) {
  //   yield put(apiError(error));
  // }

  // yield put(apiError('error'));

  // console.log('user login',user)

  // LoginUserCognito(user).then((result)=>{
  //   if(result){
  //     console.log(result)
  //   }else{
  //     yield put(apiError('Incorrect username or password.'));
  //   }
  // })

  // console.log('user',user)

  yield put(setLoadingIndicator(true));
  
  const data = {
    email : user.email
  }

// try {
// const response = yield call(validateLoginEmail, data)

  
  if(rememberText){
  let creds = {
    email : user.email,
    password : user.password
  }
  localStorage.setItem("rememberCreds", JSON.stringify(creds));
}else{
  localStorage.removeItem("rememberCreds")
}

  try {
  const userAuth = yield Auth.signIn(user.email, user.password)


  // const userDetails = {
  //   id : 1,
  //   username : userAuth.username,
  //   sub : userAuth.attributes.sub,
  //   email : userAuth.attributes.email,
  //   email_verified : userAuth.attributes.email_verified
  // }
  
  // console.log(userAuth)

  const userSession = {
   session :  userAuth.signInUserSession
  }
  const sessionExp = {
    sessionStartDate : moment().format(),
    sessionExpiryDays : 2
  }

  // localStorage.setItem(authUser, JSON.stringify(userDetails));
  localStorage.setItem(sessionStorage, JSON.stringify(userSession));
  localStorage.setItem(sessionExpiration, JSON.stringify(sessionExp))

  // const  user_email = {
  //   email : user.email
  // }

  // try {
  //   const response_user = yield call(getUserDetails, user_email)

  // console.log(userAuth)

    let userDetails = {
    username : userAuth.username,
    sub : userAuth.attributes.sub,
    email : userAuth.attributes.email,
    email_verified : userAuth.attributes.email_verified
      
    }

  // console.log(response_user.data[0])
  // localStorage.setItem(authUser, JSON.stringify({...userDetails, ...convertKeysToLowerCase(response_user.data[0])}));
  localStorage.setItem(authUser, JSON.stringify(userDetails));

  // yield put(getUserPersonalDetails(history));

      // Start the background task using fork
      yield fork(addUserSession, {username : userDetails.username, action : 'Login'});

  yield put(loginSuccess('User successfully logged in.'));

  // history('/mdr-list');
history('/complaints');

  
  // } catch (error) {
  //   console.log("There was an error loggin: ", error)
  //   yield put(apiError(error.message))
  // }
  


}catch(error){
  console.log(error)


  if (error.code === 'NotAuthorizedException') {
    yield put(apiError(`User is not authorized, Verify your email/${error.message}`));
  } else if(error.code === 'UserLambdaValidationException') {
    yield put(apiError(`User is not authorized.`));
    // yield put(apiError(`Error logging in, try after a while`));
    // yield put(apiError(`User is not authorized`));
  }

  // yield put(setLoadingIndicator(false));
}
// }catch(error){
//   console.log(error)
//   yield put(apiError(`User is not authorized`));
// }
}


function* googleLoginUser() {

  // console.log('hii')
  yield put(setLoadingIndicator(true));


  try {
    yield Auth.federatedSignIn({provider : 'Google'})
  // const userAuth = yield Auth.signIn(user.email, user.password)
  // const userDetails = {
  //   id : 1,
  //   username : userAuth.username,
  //   sub : userAuth.attributes.sub,
  //   email : userAuth.attributes.email,
  //   email_verified : userAuth.attributes.email_verified
  // }
  // const userSession = {
  //  session :  userAuth.signInUserSession
  // }
  // const sessionExp = {
  //   sessionStartDate : moment().format(),
  //   sessionExpiryDays : 2
  // }

  // localStorage.setItem(authUser, JSON.stringify(userDetails));
  // localStorage.setItem(sessionStorage, JSON.stringify(userSession));
  // localStorage.setItem(sessionExpiration, JSON.stringify(sessionExp))

  // yield put(loginSuccess('User successfully logged in.'));
  // history('/dashboard');
}catch(error){
  if (error.code === 'NotAuthorizedException') {
    yield put(apiError(`User is not authorized, ${error.message}`));
  } else {
    yield put(apiError(`Error fetching data, ${error}`));
  }
}
}

function* checkLoginUser( { payload: { history} }) {


  const isRefreshed = performance.navigation.type === 1;
    const isRedirected = performance.navigation.type === 2;

    if (isRedirected) {
      console.log('Page was redirected');

  yield put(setLoadingIndicator(true));


  try {
    // const session = yield Auth.currentSession()
    const userAuth = yield Auth.currentAuthenticatedUser({
      bypassCache: true
  })
    // console.log(userAuth)

    if(userAuth){

      try {
        const response = yield call(validateLoginEmail, {email : userAuth.attributes.email})

 
  const userSession = {
   session :  userAuth.signInUserSession
  }
  const sessionExp = {
    sessionStartDate : moment().format(),
    sessionExpiryDays : 2
  }

  // console.log(userSession)
  localStorage.setItem(sessionStorage, JSON.stringify(userSession));
  localStorage.setItem(sessionExpiration, JSON.stringify(sessionExp))

  const  user_log_data = {
    email : userAuth.attributes.email,
    username : userAuth.attributes.name,
    updatedon : moment().format()
  }

  const  user_email = {
    email : userAuth.attributes.email
  }


  try {
    const response = yield call(updateUserSignupStatus, user_log_data)


    // try {
    //   const response_user = yield call(getUserDetails, user_email)


      let userDetails = {
        username : userAuth.attributes.name,
        sub : userAuth.attributes.sub,
        email : userAuth.attributes.email,
        email_verified : userAuth.attributes.email_verified,
        
      }

    
    // localStorage.setItem(authUser, JSON.stringify({...userDetails, ...convertKeysToLowerCase(response_user.data[0])}));

    localStorage.setItem(authUser, JSON.stringify(userDetails));


      yield put(loginSuccess('User successfully logged in.'));
      // checkSocialLogin(false)
      // console.log(localStorage.getItem(sessionStorage))
      history('/complaints');
    
    // } catch (error) {
    //   // console.log("There was an error registering: ", error)
    //   yield put(apiError(error.message))
    // }
    
  
  } catch (error) {
    // console.log("There was an error registering: ", error)
    yield put(apiError(error.message))
  }
  

}catch(error){
  console.log(error)
  yield put(apiError(`User is not authorized`));
}

 
}else{
  // checkSocialLogin(false)
}
}catch(error){
  // checkSocialLogin(false)
  console.log(error)
    yield put(apiError(`User is not authorized, Verify your email/${error.message}`));
}
    }
}

function* logoutUser({ payload: { history } }) {
  try {
   
    // console.log('in out')
    if(JSON.parse(localStorage.getItem(authUser)) != null){
    yield fork(addUserSession, {username : JSON.parse(localStorage.getItem(authUser)).fld_username, action : 'Logout'})
  }
    Auth.signOut()
    localStorage.removeItem(authUser);
    localStorage.removeItem(sessionStorage);
    localStorage.removeItem(sessionExpiration);
    // checkSocialLogin(false)
    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //   const response = yield call(fireBaseBackend.logout);
    //   yield put(logoutUserSuccess(response));
    // }
    history('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}


function* socialLogin({ payload: { type, history } }) {
  // try {
  //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //     const fireBaseBackend = getFirebaseBackend();
  //     const response = yield call(fireBaseBackend.socialLoginUser, type);
  //     if (response) {
  //       history("/dashboard");
  //     } else {
  //       history("/login");
  //     }
  //     localStorage.setItem("authUser", JSON.stringify(response));
  //     yield put(loginSuccess(response));
  //   }
  //   if(response)
  //   history("/dashboard");
  // } catch (error) {
  //   // console.log("error",error)
  //   yield put(apiError(error));
  // }
}



// Function to convert perm_response to the required format
function convertToRequiredFormat(permResponse, key) {
  return permResponse.data
    .filter(perm => perm.FLD_GROUP_NAME === key) // Filter permissions based on the key and its value
    .map(perm => ({
      perm_name: perm.FLD_NAME,
      access: perm.FLD_ACCESS, // Assuming FLD_ACCESS is already in the desired 0 or 1 format
    }));
}


function* fetchUserPersonalDetails({ payload: { dispatch } }) {

  // console.log('in personal')
  yield put(setLoadingIndicatorDashboard(true));

  const userDetails = JSON.parse(localStorage.getItem(authUser))
  const  user_email = {
    email : userDetails.email
  }
  // console.log(userDetails)

  try {
    const response_user = yield call(getUserDetails, user_email)


    // console.log(response_user)
    // let userDetails = {
    // username : userAuth.username,
    // sub : userAuth.attributes.sub,
    // email : userAuth.attributes.email,
    // email_verified : userAuth.attributes.email_verified
      
    // }
    localStorage.setItem(authUser, JSON.stringify({...userDetails, user_role_tsp : 'Public', access_data :[], ...convertKeysToLowerCase(response_user.data[0])}))

  // dispatch(setLoadingIndicatorDashboard(false))
  yield put(loginPersonalUserData({...userDetails, user_role_tsp : 'Public', access_data :[], ...convertKeysToLowerCase(response_user.data[0])}));

  yield put(setLoadingIndicatorDashboard(false))
//     let tspUserData = {
//       "record_offset": 0,
//       "record_size": 10,
//       "include_favorite_metadata": false,
//       "user_identifier": response_user.data[0].FLD_USERNAME
//       }

//       try {
//         const perm_response = yield call(getGroupPermissionList)
    
//         // console.log(perm_response)
    

//  getTspToken().then((tspToken) =>{

//   // console.log(tspToken)

//         let config = {
//           method: 'post',
//           url: 'https://smarteeva.thoughtspot.cloud/api/rest/2.0/users/search',
//           headers: { 
//             'Accept': 'application/json', 
//             'Content-Type': 'application/json', 
//             'Authorization': 'Bearer '+tspToken
//           },
//           data : tspUserData
//         };

//         axios.request(config)
// .then((tspresponse) => {
//   // console.log(tspresponse);

//   let access_list =[{
//     perm_name : 'MDR Dashboard',
//     access : 1
//   },
//   {
//     perm_name : 'Recall Dashboard',
//     access : 1
//   },{
//     perm_name : 'Warning Letters',
//     access : 1
//   },{
//     perm_name : 'Smart Search',
//     access : 1
//   },{
//     perm_name : 'SmartIQ',
//     access : 1
//   }]

//   // console.log(perm_response)
//   tspresponse.data[0].user_groups.filter(val=> {
//     // console.log(val.name)
//     if(val.name == 'Administrator') {
//   localStorage.setItem(authUser, JSON.stringify({...userDetails, user_role_tsp : 'Administrator', access_data :access_list, ...convertKeysToLowerCase(response_user.data[0])}))

//   // dispatch(setLoadingIndicatorDashboard(false))
//   dispatch(loginPersonalUserData({...userDetails, user_role_tsp : 'Administrator', access_data :access_list, ...convertKeysToLowerCase(response_user.data[0])}));

//   dispatch(setLoadingIndicatorDashboard(false))
//      }else if(val.name == 'Corporate'){
//   let convertedPermissions = convertToRequiredFormat(perm_response, 'Corporate');
//   // let filteredPermissions = convertedPermissions.filter(perm => perm.access === 1);
//   localStorage.setItem(authUser, JSON.stringify({
//     ...userDetails, 
//     user_role_tsp : 'Corporate',
//     access_data: convertedPermissions, // Using filtered and converted permissions for Corporate
//     ...convertKeysToLowerCase(response_user.data[0])
//   }))
//   // dispatch(setLoadingIndicatorDashboard(false))
//   dispatch(loginPersonalUserData({
//     ...userDetails, 
//     user_role_tsp : 'Corporate',
//     access_data: convertedPermissions, // Using filtered and converted permissions for Corporate
//     ...convertKeysToLowerCase(response_user.data[0])
//   }));
//   dispatch(setLoadingIndicatorDashboard(false))


// }
// else if(val.name == 'Public'){
//   let convertedPermissions = convertToRequiredFormat(perm_response, 'Public');
//   // let filteredPermissions = convertedPermissions.filter(perm => perm.access === 1);
//   localStorage.setItem(authUser, JSON.stringify({
//     ...userDetails, 
//     user_role_tsp : 'Public',
//     access_data: convertedPermissions, // Using filtered and converted permissions for Corporate
//     ...convertKeysToLowerCase(response_user.data[0])
//   }))

//   dispatch(loginPersonalUserData({
//     ...userDetails, 
//     user_role_tsp : 'Public',
//     access_data: convertedPermissions, // Using filtered and converted permissions for Corporate
//     ...convertKeysToLowerCase(response_user.data[0])
//   }));
//   dispatch(setLoadingIndicatorDashboard(false))

// }


// })

// }).catch((error) => {
//     console.log(error);
//   });
      
//       })

//     } catch (error) {
//       console.log(error)
//       // yield put(updateGroupPermissionFail(error))
//   }
// yield put(setLoadingIndicatorDashboard(false));

    // if(response_user.data[0] == undefined){
    //   yield put(apiError(`User is not authorized, Verify your email/${error.message}`));
    //   yield put(logoutUser(history))
    // }else{
  // console.log(response_user.data[0])
  
  // localStorage.setItem(authUser, JSON.stringify({...userDetails, ...convertKeysToLowerCase(response_user.data[0])}));

  // history('/mdr-list');
  // history('/dashboard');

// console.log({...userDetails, ...convertKeysToLowerCase(response_user.data[0])})

  // yield put(loginPersonalUserData({...userDetails, ...convertKeysToLowerCase(response_user.data[0])}));
  // history('/dashboard');
  //   }
  
  } catch (error) {

    console.log(error)
    // console.log("There was an error loggin: ", error)
    // yield put(apiError(error.message))
    if (error.message === 'User unauthorised') {
      yield put(apiError(`User is not authorized, Verify your email/${error.message}`));
      Auth.signOut()
    localStorage.removeItem(authUser);
    localStorage.removeItem(sessionStorage);
    localStorage.removeItem(sessionExpiration);
    // window.location.href = '/login'
    }else{
      yield put(apiError(error.message))
    }
  }
  

}



function* verifyUserEmail( { payload: { userDetails} }) {
  
  // console.log(userDetails)

  try {
  const userAuth = yield Auth.confirmSignUp(userDetails.username, userDetails.code, userDetails.clientid)

}catch(error){
  console.log(error)
  yield put(apiError(`Something went wrong, try again later.`));


}

}


// Define the background task
function* addUserSession(userDetails) {

  let userData = {
    username : userDetails.username,
    action : userDetails.action,
    action_datetime : moment().format()
  }

  try {
    // Call your API here with the required user details
    yield call(addUserSessionData, userData);
    console.log('Background API call completed');
  } catch (error) {
    console.log('Background API error:', error);
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(GOOGLE_SOCIAL_LOGIN, googleLoginUser);
  yield takeEvery(CHECK_SOCIAL_LOGIN, checkLoginUser);
  yield takeEvery(GET_USER_PERSONAL_DETAILS, fetchUserPersonalDetails);
  yield takeEvery(VERIFY_USER_EMAIL_DETAILS, verifyUserEmail);


}

export default authSaga;
